<template>
  <div id="Contact">
    <Navigator />
    <div class="contact-cont">
      <div class="contact-t">
        <p
          class="title"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="600"
          
        >
          Contact
        </p>
        <p class="titubico">Head Quarters</p>
        <p
          class="dir"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="600"
          
        >
        Avenida Tabasco #227, Colonia San Benito, Hermosillo, Sonora, Mex.
          CP 83190<br />
          <a href="tel:+5216626881302">+521 (662) 688 1302</a><br />
          <a href="mailto:contact@davisindgroup.com"
            >contact@davisindgroup.com</a
          >
        </p>
        <p class="titubico">US Branch</p>
        <p
          class="dir"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="600"
          
        >
          
            8839 So Redwood Rd Suite D West Jordan,UT,84088 <br />
          <a href="tel:+5216628001942">+1 (385) 900 9436</a><br />
          <a href="mailto:contact@davisindgroup.com"
            >contact@davisindgroup.com</a
          >
        </p>
        <p class="txt"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-duration="600"
        
      >Automate your business. Tell us about your project.</p>
        <p class="txt due"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-duration="600"
        
      >Receive a free consultation</p>
      </div>
      <div class="form-c"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-duration="600"
        
      >
        <img src="../assets/form1.png" alt="img" class="form-img" />
        <form
          @submit.prevent="enviarMail(name, phone, email, service, mensaje)"
        >
          <!--<label>Name</label>-->
          <input
            type="text"
            name="name"
            placeholder="Name"
            v-model="name"
            class="txt_field"
          />

          <!--<label>Teléfono</label>-->
          <input
            type="text"
            name="phone"
            placeholder="Phone Number"
            v-model="phone"
            class="txt_field"
          />
          <!--<label>Correo</label>-->
          <input
            type="email"
            name="email"
            placeholder="Email"
            v-model="email"
            class="txt_field"
          />
          <!--<label>Service</label>-->

          <div class="txt-select-c" :class="{'poab':clickS == true}">
            <div
              class="txt_field pd1"
              :class="{ mb1: clickS == true }"
              @click="clickS == false ? (clickS = true) : (clickS = false)"
            >
              {{ service }}
              <img src="../assets/arrow2.svg" alt="arrow" class="arr2" />
            </div>
            <div class="txt_select" v-if="clickS == true">
              <p @click="(clickS = false), (service = 'Hardware Controls System Design')">
                Hardware Controls System Design
              </p>
              <p @click="(clickS = false), (service = 'Controls Software Design')">
                Controls Software Design
              </p>
              <p @click="(clickS = false), (service = 'Tooling and Assembly')">
                Tooling and Assembly
              </p>
              <p @click="(clickS = false), (service = 'Robotics')">
                Robotics
              </p>
              <p @click="(clickS = false), (service = 'Install & Commissioning')">
                Install & Commissioning
              </p>
            </div>
          </div>

          <!--<label>Mensaje</label>-->
          <textarea
            name="mensaje"
            cols="30"
            rows="5"
            placeholder="Your Message"
            v-model="mensaje"
            class="txt_area"
          >
          </textarea>

          <div class="advise-cont">
            <input class="btncontacto" type="submit" value="Send Message" />
            <div v-if="this.status == 'success'">
              <p class="cart_msg cart_success">{{ this.msg }}</p>
            </div>
            <div v-if="this.status == 'error'">
              <p class="cart_msg cart_error">{{ this.msg }}</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import Navigator from "../components/Navigator";
export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      service: "Choose the service",
      clickS: false,
      mensaje: "",
      status: "",
      msg: "",
    };
  },
  name: "Contact",
  components: {
    Navigator,
  },
  methods: {
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 5000);
    },
    delMsgs: function () {
      this.status = "";
      this.msg = "";
    },
    enviarMail: async function (name, phone, email, service, mensaje) {
      this.msg = "";
      this.status = "";
      if (name == undefined || name == "" || name == null) {
        this.msg = "Name field is not valid.";
        this.status = "error";
      } else if (phone == undefined || phone == "" || phone == null) {
        this.msg = "Phone field is not valid.";
        this.status = "error";
      } else if (email == undefined || email == "" || email == null) {
        this.msg = "Email is not valid.";
        this.status = "error";
      } else if (
        service == undefined ||
        service == "" ||
        service == null ||
        service == "Choose the service"
      ) {
        this.msg = "Service field is not valid.";
        this.status = "error";
      } else if (mensaje == undefined || mensaje == "" || mensaje == null) {
        this.msg = "Message field is not valid.";
        this.status = "error";
      } else {
        let data = {
          name: name,
          phone: phone,
          email: email,
          service: service,
          mensaje: mensaje,
        };
        let response = await this.$store.dispatch(
          "admin/enviarMailContacto",
          data
        );
        
        if (response.status == "success") {
          this.status = "success";
          this.msg = "Mensaje enviado con exito.";
          this.name = "";
          this.phone = "";
          this.email = "";
          this.service = "Choose the service";
          this.mensaje = "";
          this.delStatus();
        } else {
          this.status = "error";
          this.msg =
            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
        }
      }
    },
  },
};
</script>
<style>
#Contact {
  overflow: hidden;
}
.contact-cont {
  width: 78.206vw;
  margin: 0 0 34.813vw;
  padding: 60.981vw 10.397vw 0;
  background-image: url("../assets/bkimg.png");
  background-size: 59.813vw;
  background-position-x: right;
  background-position-y: 8%;
  background-repeat: no-repeat;
}
.form-img {
  width: 100%;
  height: 51.168vw;
  object-fit: cover;
  margin-bottom: 8.879vw;
}
.contact-cont p.title {
  font-style: normal;
  font-weight: 400;
  font-size: 8.178vw;
  line-height: 10.28vw;
  color: #262ffd;
  font-family: var(--MOSB_Regular);
  margin: 0 0 11.682vw;
}
p.titubico {
  color: #262ffd;
  font-family: var(--MOSB_Regular);
}
p.dir,
p.dir a {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 3.271vw;
  line-height: 210.18%;
  color: #676467;
  margin: 0;
  text-decoration-color: transparent;
}
p.txt {
  font-style: normal;
  font-weight: 400;
  font-size: 4.206vw;
  line-height: 5.374vw;
  font-family: var(--MOSB_Regular);
  width: 82%;
  color: #262ffd;
  margin: 13vw 0 0;
}
p.txt.due {
  color: #06c5ff;
  margin: 7vw 0 10vw;
}
.txt_field {
  width: 100%;
  height: 17.29vw;
  border: 0.117vw solid #196bfe;
  margin-bottom: 6.075vw;
  padding: 0 !important;
  text-indent: 8%;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 3.271vw;
  line-height: 152.68%;
  color: #adadad;
}
.txt-select-c { 
    background-color: white;
    width: 100%;
  margin-bottom: 8vw;
}
.txt_select {
  border: 0.05vw solid #196bfe;
  border-top: 0;
  width: 100%;
}
.txt_select p {
  margin: 0;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 3.271vw;
  line-height: 152.68%;
  color: #adadad;
  padding: 3vw 6vw;
  transition: 0.5s;
  cursor: pointer;
}
.txt_select p:hover {
  color: white;
  background-color: #196bfe;
}
.pd1 {
  padding-top: 6vw !important;
  height: 11.29vw !important;
  cursor: pointer;
}
.mb1 {
  margin: 0 !important;
  border-bottom: 0 !important;
}
.arr2 {
  position: absolute;
  width: 3.738vw;
  left: 69vw;
  margin-top: 0.62vw;
}
.txt_area {
  width: 100%;
  height: 48.364vw;
  padding: 5vw 0 0 !important;
  border: 0.117vw solid #196bfe;
  font-family: "Open Sans";
  line-height: 152.68%;
  font-size: 3.271vw;
  font-style: normal;
  color: #adadad;
  font-weight: 400;
  text-indent: 8%;
  resize: none;
}
.btncontacto {
  width: 100%;
  height: 21.729vw;
  background-image: url("../assets/button.png");
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  padding: 0;
  font-family: "MADE Outer Sans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 3.972vw;
  line-height: 5.14vw;
  text-align: center;
  padding-right: 10vw;
  color: #ffffff;
  margin-top: 3.037vw;
  cursor: pointer;
}
p.cart_msg {
  font-weight: 400;
  font-size: 4.206vw;
  line-height: 5.374vw;
  text-align: center;
  font-family: "MADE Outer Sans Regular";
  margin: 12vw 0 0;
  padding: 4vw 0;
}
p.cart_msg.cart_error {
  background: rgb(201, 83, 83);
  color: white;
}
p.cart_msg.cart_success {
  background: rgb(61, 107, 61);
  color: white;
}
@media (max-width:768px) {
.txt-select-c.poab{
  position:absolute;
}
}
@media (min-width: 768px) {
  .contact-cont {
    width: 83.646vw;
    background-position-x: 75%;
    background-position-y: -25%;
    background-size: 49.271vw;
    margin: 0 0 16.25vw;
    padding: 16.615vw 8.646vw 0;
  }
  .contact-cont p.title {
    font-size: 3.646vw;
    line-height: 4.635vw;
    margin: 0 0 2.031vw;
  }
  p.titubico {
    margin-top: 2.5vw;
  }
  p.dir,
  p.dir a {
    font-size: 0.938vw;
    line-height: 210.18%;
    width: 23.344vw;
  }
  p.txt {
    font-size: 1.563vw;
    line-height: 1.979vw;
    width: 42%;
    margin: 5.781vw 0 0;
  }
  .txt.due {
    margin: 2.781vw 0 0;
  }
  .form-c {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 6.906vw;
  }
  .form-img {
    width: 29.063vw;
    height: 24.01vw;
    margin-bottom: 8.879vw;
  }
  .txt-select-c {
    width: fit-content;
    margin: 0;
}
  .txt_field {
    width: 24.74vw;
    height: 3.854vw;
    margin-bottom: 1.771vw;
    font-size: 0.938vw;
    line-height: 152.68%;
  }
  .txt_select {
    width: 24.74vw;
    margin-bottom: 2vw;
    position: absolute;
    background: white;
}
  .txt_select p {
    font-size: 0.938vw;
    line-height: 152.68%;
    padding: 1vw 2vw;
  }
  .pd1 {
    padding-top: 1vw !important;
    height: 2.854vw !important;
  }
  .arr2 {
    position: absolute;
    width: 0.833vw;
    left: 48.7vw;
    margin-top: 0.413vw;
  }
  .form-c form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 51.615vw;
  }
  .txt_area {
    width: 100%;
    height: 9.152vw;
    border: 0.117vw solid #196bfe;
    padding: 2vw 0 0 !important;
    font-size: 0.938vw;
    line-height: 152.68%;
    text-indent: 4%;
  }
  .btncontacto {
    font-size: 0.938vw;
    line-height: 1.198vw;
    width: 25.208vw;
    height: 4.844vw;
    background-image: url("../assets/bkimg2.png");
    background-repeat: no-repeat;
    background-size: 100%;
    padding-right: 4vw;
  }
  p.cart_msg {
    font-size: 0.706vw;
    line-height: 0.706vw;
    margin: 1vw 0 0;
    padding: 1vw;
  }
}
</style>